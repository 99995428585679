// @import "../../../scss/mixins";

.main-wrapper {
  background-color: #eee;

  main.main {
    // overflow: hidden;
    width: calc(100% - 280px);
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    overflow-y: scroll;
    height: 100vh;
    background-color: #f8f9fb;
  }
}

.editLogo {
  display: inline-block;
  position: relative;
  cursor: pointer;

  > i {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

// .logocls {
//   width: 100%;
//   max-width: 185px !important;
// }

.dash-title {
  position: relative;
  background-color: #f6f8fb;
  padding: 11px 19px;
  font-size: 28px;
  line-height: normal;
  border-radius: 6px;
  margin-bottom: 30px;

  .total {
    font-size: 14px;
    float: right;
    font-weight: 600;
    margin-top: 10px;
  }
}

  .main-sidebar {
    position: relative;
    background-color: #F9FAFB;

    width: 100%;
    height: 100vh;
     margin-top: -72px;
    border-right: 1px solid #E8E8E8;
    // position: sticky;
    // top: 0px;
    // overflow: auto;

  .sidebar-brand img {
  }
}

@media (max-width: 767px) {
  .main-wrapper main.main {
    width: calc(100% - 280px);
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;

}
}

@media (max-width: 467px) {
  .form-row.uploaded-image-row > div {
    max-width: 50%;

    .uploaded-image img {
      height: 100px;
    }
  }
}

img.hide-logo {
  display: none;
}

.active-sidebar img.hide-logo {
  display: block !important;
  width: 20px;
}

.show-logo{
  display: block;
  
}

.active-sidebar .show-logo{
  display: none;
}
.min-sidebar ~ div > .main-sidebar .sidebar-brand{
  visibility: visible !important;
  width: 20px !important;
  margin: 0 auto !important;
}
