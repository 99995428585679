@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=East+Sea+Dokdo&family=Recursive:wght@300..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Dancing+Script:wght@400..700&family=East+Sea+Dokdo&family=Recursive:wght@300..1000&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.invalid {
	border: 1px solid red;
}

.urlTag a {
	text-decoration: underline;
}
.Toastify__toast-container--top-right {
	z-index: 999999 !important;
}
.requiredField {
	border: 2px solid #eb6719ed !important;
	box-shadow: 1px 0px 5px #eb671999 !important;
}
.text-primary {
	color: #481196;
}

.swal-overlay.swal-overlay--show-modal {
	z-index: 9999999;
}
.swal-overlay.swal-overlay--show-modal .swal-modal {
	width: 979px;
	padding: 78px;
	border-radius: 8px;
}
.swal-overlay.swal-overlay--show-modal .swal-modal .swal-title {
	font-family: Inter;
	font-size: 48px;
	font-weight: 600;
	line-height: 48px;
	text-align: center;
	padding: 0;
	margin-top: 48px;
	color: #2b2b2b !important;
}
.swal-button.swal-button--confirm {
	height: 50px;
	padding: 13px 27px;
	border-radius: 6px;
	background-color: #481196;
	border: 1px solid #e1e1e1;
	line-height: 1;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	text-align: center;
	color: #fff;
}
.swal-button.swal-button--confirm:hover {
	background-color: #481196;
}
.swal-button.swal-button--cancel {
	height: 50px;
	padding: 13px 27px;
	border-radius: 6px;
	border: 1px solid #e1e1e1;
	line-height: 1;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	text-align: center;
	color: #2b2b2b;
	background-color: #fff;
}
.swal-button.swal-button--cancel:hover {
	background-color: #fff;
}
.font1 {
	font-family: "Cedarville Cursive", cursive;
	font-weight: 400;
	font-size: 40px;
	font-style: normal;
}

.font2 {
	font-family: "East Sea Dokdo", sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 40px;
}

.font3 {
	font-family: "Dancing Script", cursive;
	font-optical-sizing: auto;
	font-weight: 500;
	font-style: normal;
}

.confirmpopupImg {
	max-width: 300px;
	width: 100%;
	display: inline-block;
}

.selectDropdown,
.multiSelectContainer,
.css-1q630no-menu {
	text-transform: capitalize;
}

.selectDropdown {
	/* z-index: 30; */
}

.recommendationData pre {
	white-space-collapse: initial;
	text-wrap: initial;
	font-family: inherit;
}

.recommendationData h1 {
	font-size: 14px;
	font-weight: 600;
	color: #111827;
}

.recommendationData li {
	border-radius: 12px;
	padding: 16px;
	margin-top: 12px;
	border: 1px solid #ebebeb;
	font-size: 12px;
}

.recommendationData ol {
	margin-bottom: 10px;
}
.recommendationData p {
	font-size: 12px;
	color: #333a41;
	font-weight: 500;
}

.material-icons,
.material-symbols-outlined {
	display: inline-block;
	vertical-align: middle;
}

.MuiSvgIcon-root {
	width: 20px;
	display: inline-block;
	color: #7272e8;
}

.legends {
	display: flex;
	justify-content: center;
}
.legends .color {
	height: 12px;
	width: 12px;
}

.legends .legendItem {
	display: flex;
	align-items: center;
	column-gap: 5px;
	cursor: pointer;
	font-size: 14px;
}

.legendItem.active {
	opacity: 0.5;
}

.charttooptip {
	width: fit-content;
	/* min-width: 200px; */
}

.tooltipCal {
	word-break: break-word;
}

.react-tel-input .selected-flag .flag {
	background-color: #d5d5d5;
}

.list_box_active_state button {
	text-transform: capitalize;
}

.user_card,
.user_detail {
	align-items: center;
	display: flex;
	text-align: left;
}
img.user_imgs {
	border-radius: 50%;
	height: 36px;
	object-fit: cover;
	width: 36px;
}

.contain-img {
	object-fit: contain;
}

.user_name {
	margin: 0 10px;
}

h4.user {
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	color: #1d2433;
	display: -webkit-box;
	font-size: 14px;
	font-weight: 500;
	margin: 0;
	text-transform: capitalize;
}

.shine {
	background: #eee;
	background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
	background-size: 200% 100%;
	animation: 1.5s shine linear infinite;
	min-height: 14px;
	border-radius: 5px;
}

.shineInput {
	height: 40px;
	width: 220px;
}

.shineCard {
	min-height: 300px;
}

strong.shine {
	display: block;
	margin-bottom: 14px;
}
@keyframes shine {
	to {
		background-position-x: -200%;
	}
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-msbox-sizing: border-box;
}

.flag_img {
	width: 40px;
}

.d-none {
	display: none;
}

.webviewer {
	height: calc(100vh - 83px);
}
.modal {
	background-color: #0000006b;
	position: fixed;
	min-height: 100vh;
	width: 100%;
	display: none;
	/* align-items: center; */
	justify-content: center;
	top: 0;
	left: 0;
	z-index: 9999;
	padding-top: 50px;
	overflow: auto;
}

.modal.show {
	display: flex;
}

.modal-content {
	background-color: #fff;
	padding: 15px;
	border-radius: 6px;
}

.modal-header {
	display: flex;
	justify-content: space-between;
}

.modal-title {
	font-size: 16px;
	font-weight: 500;
}

.modal-header .close {
	font-size: 23px;
	cursor: pointer;
	display: inline-block;
	line-height: normal;
}

.modal-dialog {
	width: 100%;
	max-width: 600px;
}

.modal-footer {
	display: flex;
	justify-content: end;
	column-gap: 10px;
	padding-top: 15px;
}

.inputWrapper {
	position: relative;
	.sufix {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
	}
}

html,
body {
	scroll-behavior: smooth;
	font-family: "Inter", sans-serif;
	font-style: normal;
	background-color: #f8f9fc;
}

input::placeholder,
select::placeholder,
textarea::placeholder,
button,
input,
optgroup,
select,
textarea {
	font-family: "Inter", sans-serif;
	font-style: normal;
}

/* custom scrollbar  */
/* width */
.scrollbar::-webkit-scrollbar,
#scrollbar::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track,
#scrollbar::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb,
#scrollbar::-webkit-scrollbar-thumb {
	background: #ddd;
	border-radius: 40px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover,
#scrollbar::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.react-pagination-js-default {
	width: 100%;
}

.react-pagination-js-default ul {
	justify-content: end !important;
}
.react-pagination-js-default ul > li:last-child {
	/* margin-left: auto !important; */
	border-radius: 8px;
	background: white;
	border: 1px solid #d0d5dd !important;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
	padding: 10px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	color: rgba(52, 64, 84, 0.48) !important;
	font-size: 14px !important;
	font-weight: 500 !important;
}
.react-pagination-js-default ul > li:first-child {
	/* margin-right: auto !important; */
	border-radius: 8px;
	background: white;
	border: 1px solid #d0d5dd !important;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
	padding: 10px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	color: rgba(52, 64, 84, 0.48) !important;
	font-size: 14px !important;
	font-weight: 500 !important;
}

.react-pagination-js-default ul {
	display: flex;
	align-items: center;
	column-gap: 10px;
}
.react-pagination-js-default ul > li {
	margin: 0 !important;
	font-size: 13px !important;
	padding: 7px 13px !important;
}

.react-pagination-js-default ul > li:first-child::after {
	content: "< Previous";
}
.react-pagination-js-default ul > li:first-child a {
	display: none;
}
.react-pagination-js-default ul > li:last-child:not(.disabled) {
	color: #7f56d9 !important;
}
.react-pagination-js-default ul > li:last-child::after {
	content: "Next >";
}
.react-pagination-js-default ul > li:last-child a {
	display: none;
}
.react-pagination-js-default ul > li.is-active {
	font-weight: 500 !important;
	border-color: transparent !important;
	box-shadow: none !important;
	text-shadow: none !important;
	border-radius: 8px !important;
	color: #7f56d9 !important;
	background: #f9f5ff !important;
}

.react-pagination-js-default ul > li:first-child:not(.disabled) {
	color: #481196 !important;
	font-size: 14px !important;
	font-weight: 500 !important;
}
.react-pagination-js-default ul > li:last-child:not(.disabled) {
	color: #481196 !important;
	font-size: 14px !important;
	font-weight: 500 !important;
}

.react-pagination-js-default li.page {
	border-color: #0000 !important;
	background-color: transparent !important;
	box-shadow: none !important;
	font-weight: 400 !important;
}

.collapse {
	visibility: inherit;
}

/* // custom input checkbox and radio start */
input[type="radio"],
input[type="checkbox"] {
	/* -moz-appearance: none;
	-webkit-appearance: none; */
	/* appearance: none; */
}
input[type="radio"]:checked,
input[type="checkbox"]:checked {
	/* -moz-appearance: none; */
	/* -webkit-appearance: none; */
	/* appearance: none; */
	background-color: currentColor;
	background-position: 50% 80%;
	background-repeat: no-repeat;
	background-size: 90% 90%;
	border-color: transparent;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
	-webkit-user-select: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
}

.green_check[type="radio"]:checked,
.green_check[type="checkbox"]:checked {
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M10 3L4.5 8.5L2 6" stroke="%2300B396" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

/* /* .react-datepicker-wrapper  */
.react-datepicker-wrapper {
	width: 100%;
}

.select__control {
	width: 100%;
	border-width: 0 !important;
	box-shadow: none !important;
	border-color: transparent !important;
}

iframe#webpack-dev-server-client-overlay {
	display: none !important;
}

/* focus buttons  */
.list_box_active_state button[data-headlessui-state="open"] {
	outline-color: #481196;
	outline-width: 2px;
	outline-style: solid !important;
}

.search-wrapper:focus-within {
	outline: 2px solid #481196 !important;
}

.optionListContainer {
	outline: 2px solid #481196 !important;
	margin-top: 8px !important;
}
.chip {
	background: #481196 !important;
	border-radius: 5px !important;
	white-space: normal !important;
}

.searchWrapper {
	border-radius: 24px !important;
	box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
		0px 1px 2px 0px rgba(164, 172, 185, 0.24);
	background: #fff;
	width: 100%;
	font-size: 14px;
	height: 40px;
	border: none !important;
	padding:5px 12px;
}

.multiselect-container .highlightOption {
	background: transparent;
	color: inherit;
}

.multiselect-container .option:hover {
	background: #f3f4f6 !important;
	color: inherit !important;
}

.multiselect-container .optionListContainer ul::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

.multiselect-container .optionListContainer ul::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.multiselect-container .optionListContainer ul::-webkit-scrollbar-thumb {
	background: #ddd;
	border-radius: 40px;
}

.multiselect-container .optionListContainer ul::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.react-tooltip {
	max-width: 280px;
	word-break: break-all;
	white-space: initial;
	background: #fff !important;
	color: #000 !important;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.loaderDiv {
	z-index: 99999999;
	backdrop-filter: blur(2px);
	align-items: center;
	justify-content: center;
	display: flex;
	position: fixed;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
}

.pageLoader {
	max-width: 50px;
}
.react-tel-input .form-control {
	width: 100% !important;
}
.google-visualization-tooltip {
	border-radius: 4px !important;
	overflow: hidden !important;
	box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 10px !important;
	border: 1px solid #fff !important;
}
/* -item-list */

p.text-center.pcls {
	margin-bottom: 20px;
}
.social_search {
	max-width: 800px;
	margin: auto;
}
.back_icon {
	color: #525252 !important;
	font-size: 22px !important;
}
a.btn.btnsecondary.backBtn.w-25 {
	background: #ddd;
	display: flex;
	align-items: center;
	justify-content: center;
}

.social_search table th {
	font-weight: 400;
	color: #585858;
	background: #f4f8ff;
	border: 1px solid #efefef;
}
.buttonSection button {
	background: #481196;
	border: 1px solid #481196;
}

/* ############### */

table.table.table-striped {
	width: 100%;
}

a.btn.btnsecondary.backBtn.w-25 {
	background: #ddd;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 260px;
	height: 37px;
	border-radius: 6px;
}
.buttonSection button {
	background: #481196;
	border: 1px solid #481196;
	border-radius: 6px;
	/* width: 100%; */
	color: #fff;
	height: 37px;
}
/* .social_search table th {
  padding: 5px 0px;
} */

.social_search table td {
	padding: 5px 0px;
}

.flex.justify-content-between.w-50.mx-auto.mb-2 {
	justify-content: space-between;
}
.w-full.bg-gray-200.rounded-full.h-2\.5.dark\:bg-gray-700 {
	margin-bottom: 2.5rem;
}
.bg-blue-600.h-2\.5.rounded-full {
	background: linear-gradient(to left, rgb(105 86 229), rgb(118, 90, 166));
}

.searchWrapper {
	border-radius: 24px !important;
	box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
		0px 1px 2px 0px rgba(164, 172, 185, 0.24);
	background: #fff;
	width: 100%;
	font-size: 14px;
	height: auto;
	border: none !important;
}

/* ############## */

.drag_input input {
	min-height: 155px;
	border-radius: 12px;
	position: absolute;
	opacity: 0;
	z-index: 9;
	cursor: pointer;
}

.drag_input {
	border: 1px solid #cbcaff;
	border-radius: 10px;
}

.upload_imginput {
	text-align: center;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.upload_imginput .material-icons {
	color: #481196;
	box-shadow: 0px 0px 10px -6px #000;
	padding: 6px;
	border-radius: 50%;
	margin-bottom: 12px;
}

.upload_imginput p {
	margin-bottom: 0px;
	font-size: 14px;
}

/* 
  button.RCK.Hsu.USg.Vxj.aZc.Zr3.hA-.GmH.adn.a_A.gpV.hNT.iyn.BG7.gn8.L4E.kVc {
    background: #645dde !important;
	color: #fff !important;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.tBJ.dyH.iFc.yTZ.pBj.tg7.mWe {
    color: #fff;
}

.Eqh.FNs.JME.zI7.iyn.Hsu svg {
    color: #fff;
}

.wYR {
    padding-bottom: 10px !important;
}

.Jea.gjz.zI7.iyn.Hsu {
    justify-content: center;
	font-size: 14px;
}

.sxy {
	width: 20.66667%;
}

.Shl.zI7.iyn.Hsu {
	width: 100%;
} */

.auxiliary.text input {
	border: 2px solid #481196 !important;
}

.App input[type="text"] {
	font-family: Lato;
	border: 1px solid #000 !important;
}

/* .Fje {
	align-items: flex-start;
} */

.grid.grid-cols-1.gap-4.mb-4.shadow-box.p-6.bg-white.rounded-large.stikcyclass {
	position: sticky;
	top: 80px;
}

.css-1q630no-menu {
	position: relative;
	z-index: 999;
}

/* .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    z-index: 999999;
} */

.erroe {
	width: auto;
	margin: 0 auto;
	height: 600px;
}

/* CHROME */
#range {
	-webkit-appearance: none;
	outline: none;
	background: #e9e9e9;
	height: 14px;
	border-radius: 41px;
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background: #481196;
	}
}

/* FIREFOX */
#range::-moz-range-thumb {
	border: none;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	background: #481196;
	cursor: pointer;
}

#range::-moz-range-track {
	width: 100%;
	height: 3px;
	cursor: pointer;
	background: #e9e9e9;
	border-radius: 41px;
}

.data_try {
	width: 120px;
}

.texteditor p {
	color: #6b7280 !important;
	font-weight: 300 !important;
	font-size: 0.875rem !important;
	line-height: 1.25rem !important;
}

.main-sidebar.scrollbar.transition-\[width\].duration-500 {
	transition: all 0.5s ease-in-out;
}

.block.w-full.autocomplte > div {
	width: 100%;
}

.selected_drops .selectDropdown .list_box_active_state button {
	margin-left: auto !important;
}

/* .list_box_active_state button {
	text-transform: capitalize;
	margin-left: auto;
} */

.tree > li.tree-branch-wrapper {
	margin-bottom: 20px;
	border-bottom: 1px solid #d0d5dd;
}
.active-sidebar .pl-\[25px\] {
	padding-left: 5px !important;
}

/* .active-sidebar ul.space-y-2 {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
} */

.active-sidebar .sidebar_text span {
	font-size: 5px !important;
	margin-top: 2px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

#multiselectContainer .search-wrapper.searchWrapper {
	box-shadow: none !important;
}
#multiselectContainer .search-wrapper:focus-within {
	outline: none !important;
}

#multiselectContainer .optionListContainer {
	outline: none !important;
}
#multiselectContainer .chip {
	background: #edecf9 !important;
	border-radius: 32px !important;
	height: 32px;
	padding: 8px 12px 8px 12px;
	gap: 8px;
	align-items: center;
	color: #705ee6;
}

#multiselectContainer ul {
	color: #ebebeb;
	border-radius: 8px;
}

#multiselectContainer .optionListContainer {
	border-radius: 8px;
	overflow: hidden;
}

#categories,
#categories .search-wrapper.searchWrapper {
	min-height: 40px;
}
#categories .search-wrapper.searchWrapper .searchBox {
	height: 30px;
	margin: 0;
	cursor: pointer;
}

#productCat,
#productCat .search-wrapper.searchWrapper {
	min-height: 40px;
}
#productCat .search-wrapper.searchWrapper .searchBox {
	height: 30px;
	margin: 0;
}

#products,
#products .search-wrapper.searchWrapper {
	min-height: 40px;
}
#products .search-wrapper.searchWrapper .searchBox {
	height: 30px;
	margin: 0;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 18px;
	height: 18px;
	border-radius: 10px;
	background-color: #481196;
	overflow: visible;
	cursor: pointer;
	transition: all 1s ease;
}
/* -------------------------- Firefox */
.slider::-moz-range-thumb {
	-webkit-appearance: none;
	width: 18px;
	height: 18px;
	border-radius: 10px;
	background-color: #481196;
	overflow: visible;
	cursor: pointer;
	transition: all 1s ease;
}
.smallmodal {
	width: 400px !important;
	padding: 5px !important;
	border-radius: 8px;
}

.swal-button.swal-button--confirm {
	background-color: #5d62df !important;
}

.smallmodal .swal-title {
	font-family: Inter;
	font-size: 18px !important	;
	font-weight: 600;
	line-height: 26px !important;
	text-align: center;
	padding: 0;
	margin-top: 20px !important;
	color: #2b2b2b !important;
}

.smallmodal .swal-footer {
	margin-top: 0px !important;
}
.react-datepicker__day--selected {
	background-color: #481196 !important;
}
.data_h1 p a{
	font-weight:600;
	color: #111827;
	&:hover{
		color: #5d62df;
		transition: all ease .2s;
		text-decoration: underline;
	}
}

#userPhoneSelect .selected-flag:hover{
	background-color:transparent;
}
.country_sales_chart svg {
	border-bottom-right-radius: 20px;
}
.react-tel-input .selected-flag:hover {
	background-color: transparent;
}
.react-tel-input .flag-dropdown.open .selected-flag {
	background-color: transparent;
}


.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
    border-color: #4b1991 !important;
    border-color: #4b1991 !important;
    background-color: #4b1991 !important;
    background-color: #4b1991 !important;
    color: #f7f7fa;
    color: var(--rs-text-inverse);
}

.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
    border-color: #4b1991 !important;
    border-color: var(--rs-steps-state-finish);
    color: #4b1991 !important;
    color: var(--rs-steps-state-finish);
}

.rs-steps-item-status-finish .rs-steps-item-tail, .rs-steps-item-status-finish .rs-steps-item-title::after {
    border-color: #4b1991 !important;
    border-color: var(--rs-steps-state-finish);
}

.bgimages{
	background-image: url('../public/assets/img/bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
}


.rs-panel-body{
	padding: 20px 0px !important;
}

.rs-panel {
    border-radius: 6px;
    overflow: visible !important;
}

.texteditor p {
    width: 300px;
    text-align: center;
    margin: 0 auto;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
}
